import React, { useState, useEffect, useRef, useContext } from 'react';
import {
    DataGrid,
    Selection,
    Column,
    Paging,
    Editing,
    Item,
    Lookup,
    HeaderFilter,
    Toolbar,
    Sorting,
    Filter
} from 'devextreme-react/data-grid';
import SelectBox from 'devextreme-react/select-box';
import { CustomRule } from 'devextreme-react/validator';
import axios from 'axios';
import { formatPhoneNumber, notify } from '../../helper';
import { useReviewQuoteContext } from '../../ReviewQuoteContext.js';
import Collapser from '../common/Collapser';

const PortingTable = () => {
    const { quote, generatingPDF, reviewingContract, contact } = useReviewQuoteContext();
    const [portingNumbers, setPortingNumbers] = useState([]);
    const [didTags, setDIDTags] = useState([]);
    const [editMode, setEditMode] = useState('cell');
    const [selectedRows, setSelectedRows] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const dataGrid = useRef(null);
    useEffect(() => {
        const controller = new AbortController();
        if (quote.quoteID) {
            getPreQ(controller);
        }
        return () => controller.abort();
    }, [quote]);

    useEffect(() => {
        if (editMode === 'row') {
            dataGrid.current.instance.addRow();
        }
    }, [editMode]);

    const switchTypes = [{ name: 'Voice', id: 1 }, { name: 'Fax', id: 2 }];
    const getPreQ = async (controller) => {
        let allPreQ = [];
        const requestConfig = controller ? controller.signal : null;
        const { data: quoteDIDs } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetPortingDIDs?quoteID=${quote.quoteID}`, requestConfig);
        if (controller.signal.aborted) {
            return;
        }
        allPreQ = quoteDIDs.preQ;
        if (quote.projectID) {
            const { data: projectDIDs } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetPortingDIDs?projectID=${quote.projectID}`, requestConfig);
            allPreQ = [...allPreQ, ...projectDIDs.preQ];
        }
        setPortingNumbers(allPreQ?.map(p => {
            p.switchName = p.switch === 8 ? 2 : 1;
            return p;
        }));
    };

    const addPreQ = async (row, edit, massEdit) => {
        const rowData = { ...row.data };
        const { switchName, rowID } = rowData;
        const preQ = {
            ...rowData,
            quoteID: quote.quoteID,
            DID: rowData.did,
            CNAM: rowData.cnam,
            rowID: edit ? rowID : 0,
            switch: switchName === 2 ? 8 : (rowData.switch === 8 && switchName === 1) ? 0 : rowData.switch
        };
        if (massEdit) {
            preQ.switch = rowData.switch === 2 ? 8 : 0;
        }
        // const url = quote.projectID ? '/Api/Quotes/PostToCore?route=/API/Quotes/AddProjectDID' : '/Api/Quotes/PostToCore?route=/API/Quotes/AddPortingPreQ';
        const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/AddPortingDID', preQ);
        let copy = [...portingNumbers];
        let addedPreQ = copy.find(p => p.rowID === rowID);
        if (!edit && data.result) {
            if (addedPreQ) {
                addedPreQ.rowID = data.rowID;
                addedPreQ.did = rowData.did;
            } else {
                getPreQ();
            }
        } else if (massEdit) {
            addedPreQ.switchName = rowData.switch;
        }
        if (!addedPreQ) {
            addedPreQ = portingNumbers.find(p => p.rowID === data.rowID);
        }
        if (addedPreQ) {
            // Object.keys(locationInfo).map(k => updateRow(k, addedPreQ));
        }
        if (!data.result && !edit) {
            copy = copy.filter(p => p.rowID !== rowData.rowID);
        }
        if (!data.result) {
            notify(data.msg, 'error');
        }
        setPortingNumbers(copy);
        return data.result;
    };
    const toolbarTitle = () =>
        <div style={{ color: '#707070' }} className='mb-0 h5'>
            <b>Porting Numbers</b>
        </div>;

    const switchTypeSelect = () => {
        return generatingPDF
            ? ''
            : <SelectBox
                dataSource={switchTypes}
                placeholder="Select Switch Type"
                dataType="string"
                displayExpr="name"
                valueExpr="id"
                onValueChanged={onMassUpdateSwitch}>
            </SelectBox>;
    };

    const onMassUpdateSwitch = (e) => {
        console.log(e.value);
        if (!selectedRows.length) {
            return;
        }
        selectedRows.map(p => {
            return addPreQ({ data: { ...p, switch: e.value } }, true, true);
        });
    };

    const onDeleteClick = async (row) => {
        const quoteID = quote.quoteID;
        const rows = [];
        if (row?.data) {
            row.data.quoteID = quoteID;
            rows.push(row.data);
        } else {
            const mappedSelectedRows = selectedRows.map(r => {
                return { ...r, quoteID };
            });
            rows.push(...mappedSelectedRows);
        }
        // const url = quote.projectID ? '/Api/Quotes/PostToCore?route=/API/Quotes/DeleteProjectDIDs' : '/Api/Quotes/PostToCore?route=/API/Quotes/DeletePreQs';
        const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/DeletePortingDIDs', { orders: rows, externalContactID: contact.contactID || null });
        if (selectedRows.length && data.result) {
            getPreQ();
            // closeConfirmModal();
        }
    };

    // const closeConfirmModal = () => setShowConfirmationModal(false);

    const formatDID = (row) => {
        const did = row.did;
        const formated = formatPhoneNumber(did);
        return formated ?? did;
    };

    const validateDID = (e) => {
        e.rule.message = (!e.data.did && !didTags.length) ? 'This is a required field' : "That's not a valid number";
        return (+e.data.did && e.data.did?.length <= 10) || (didTags.length && didTags.every(d => d.length <= 10 && +d));
    };

    const onEditorPreparing = (e) => {
        if (e.row?.modified) {
            return;
        }
        if (e.dataField === 'did') {
            e.editorOptions.value = portingNumbers.find(p => p.rowID === e.row.data.rowID)?.did;
        }
    };

    const onRowInserted = async (row) => {
        setEditMode('cell');
        console.log(didTags);
        if (!didTags.length) {
            addPreQ(row);
        } else {
            didTags.map(d => {
                return addPreQ({ ...row, data: { ...row.data, did: d } });
            });
            setDIDTags([]);
        }
    };

    const onRowUpdated = (row) => {
        addPreQ(row, true);
    };

    // const didEditorRender = () => {
    //    return <div onChange={onDIDChange}>
    //        <InputTags
    //            values={didTags}
    //            onTags={(value) => setDIDTags(value.values)}
    //            placeholder="Add/paste numbers sep. by commas or spaces"
    //            elementClassName="border border-primary did-tag m-1" />
    //    </div>
    // }

    const onDIDChange = (e) => {
        let dids = e.target.value.split(/[\s,]+/);
        dids = dids.filter((d, i, arr) => d && arr.indexOf(d) === i);
        if (dids.length > 1) {
            setDIDTags([...didTags, ...dids]);
        }
    };

    const statusEditorRender = (cell) => {
        return <SelectBox
            placeholder="Choose Switch Type..."
            defaultValue={cell.value}
            {...cell.column.lookup}
            onValueChanged={(e) => cell.setValue(e.value)}
        />;
    };

    const onInitNewRow = () => {
        setEditMode('row');
    };

    const onEditCanceling = () => {
        setEditMode('cell');
        setDIDTags([]);
    };

    const toolBarItems = ['saveButton', 'revertButton', 'columnChooserButton', 'addRowButton'];

    const userCanEdit = !reviewingContract && !generatingPDF && quote.quoteHash.canEditPorting;

    const collapser = () => {
        return <Collapser fullView={showTable} onChange={() => setShowTable(!showTable)} />;
    };

    return (
        <div className=''>
            {showTable && <DataGrid
                onSelectionChanged={({ selectedRowsData }) => { setSelectedRows(selectedRowsData); }}
                ref={dataGrid}
                id="porting-grid"
                dataSource={portingNumbers}
                keyExpr="rowID"
                allowColumnReordering={true}
                showBorders={true}
                onInitNewRow={onInitNewRow}
                onEditorPreparing={onEditorPreparing}
                onRowInserted={onRowInserted}
                onRowUpdated={onRowUpdated}
                onRowRemoved={onDeleteClick}
                onEditCanceling={onEditCanceling}
                columnHidingEnabled={!generatingPDF}
                rowAlternationEnabled={true}>
                <Paging enabled={false} />
                {userCanEdit && <Editing
                    mode={editMode}
                    useIcons={true}
                    allowUpdating={true}
                    allowDeleting={true}
                    allowAdding={true} />
                }
                <Toolbar>
                    <Item location="before"
                        render={toolbarTitle}
                    ></Item>
                    {userCanEdit && <Item render={switchTypeSelect}
                    ></Item>}
                    {userCanEdit && toolBarItems.map((n, i) => <Item key={i} name={n}></Item>)}
                    <Item location="after"
                        render={collapser}
                    ></Item>
                </Toolbar>
                <Sorting mode="none" /> {/* or "multiple" | "none" */}
                <HeaderFilter visible={userCanEdit} />
                <Column dataField="did"
                    dataType="string"
                    caption="Numbers"
                    // {/*                    {...(editMode === "row" && { editCellRender: didEditorRender })} //only passing in prop if adding a new row*/}
                    calculateCellValue={formatDID}>
                    <CustomRule
                        validationCallback={validateDID}
                        reevaluate={true}
                    />
                </Column>
                <Column dataField="switchName"
                    caption='Service'
                    editCellRender={statusEditorRender}>
                    <Lookup
                        dataSource={switchTypes}
                        displayExpr="name"
                        valueExpr="id"
                    />
                </Column>
                <Column dataField="currentProvider" dataType="string" caption="Current Carrier" hidingPriority={generatingPDF ? '' : 1} />
                <Column dataField="accountNumber" dataType="string" caption="Account Number" hidingPriority={generatingPDF ? '' : 0} />
                <Column dataField="name" caption="Auth Name" dataType="string" />
                <Column dataField="notes" dataType="string" hidingPriority={generatingPDF ? '' : 2} />
                {userCanEdit && <Selection
                    mode="multiple"
                    selectAllMode={'page'}
                    showCheckBoxesMode={'always'}
                />}
            </DataGrid>}
            {!showTable && <div className='d-flex justify-content-between'>
                {toolbarTitle()}
                {collapser()}
            </div>}
        </div>
    );
};
export default PortingTable;
